import PageBanner from "../includes/PageBanner";

function About() {
    return (
        <div className="service-page">
            <section id="Banner"><PageBanner banner="assets\img\page-banner\ABOUT US .jpg" /></section>
            <section id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center my-3">
                            <h1>Message from President</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 my-3 text-center">
                            <img src="assets/img/team/owner-picture.jpg" alt="" />
                        </div>
                        <div className="col-md-6 my-3 pl-4">
                            <p className="About-us"><span>We offer variety</span><span> of metal products that meet the specifications of customers in a dynamic range of industries such as aluminum, copper, Lead, Zinc , Brass. Today, with the emergence of the internet and social media, news on major political and economic changes spread around the world instantaneously. We live in the era where the outlook for metal products in the global markets could change significantly overnight.</span></p>
                            <p className="About-us"><span>With this in mind,</span><span> we closely communicate and coordinate with us with the aim of coping with sudden and unpredictable shifts in the marketplace.Our goal is to make every effort to provide our customers with metal products, along with strategic market information, that will meet their needs and promote a competitive advantage to meet both short and long term goals.</span></p>
                        </div>
                    </div>
                    <h1 className="membership">Memberships & Licenses</h1>
                    <div className="row mt-5">
                  
                        <div className="col-md-3">
                            <img className="img-fluid about-cert-img-border" src="assets/img/certificates/cer-1.jpeg" alt="" />
                        </div>    
                        <div className="col-md-3">
                            <img className="img-fluid about-cert-img-border" src="assets/img/certificates/cer-2.jpeg" alt="" />
                        </div>    
                        <div className="col-md-3">
                            <img className="img-fluid about-cert-img-border" src="assets/img/certificates/cer-3.jpeg" alt="" />
                        </div>     
                        <div className="col-md-3">
                            <img className="img-fluid about-cert-img-border" src="assets/img/certificates/cer-4.jpeg" alt="" />
                        </div>    
                    </div>
                    <div className="row my-5">
                        <div className="col-md-4">
                            <img className="img-fluid" src="assets/img/certificates/card-1.jpeg" alt="" />
                        </div>    
                        <div className="col-md-4">
                            <img className="img-fluid" src="assets/img/certificates/card-2.jpeg" alt="" />
                        </div>    
                        <div className="col-md-4">
                            <img className="img-fluid" src="assets/img/certificates/card-3.jpeg" alt="" />
                        </div>    
                    </div>
                    


                    <div className="aboutt">
                            <img className="img-fluidy" src="assets\img\certificates\WhatsApp Image 2022-01-20 at 2.09.45 AM.jpeg" alt="" />
                        </div> 
                        
                            
                        
                </div>
            </section>
        </div>
    );
  }
  
  export default About;