import React from "react";

const PageNotFound = () => {
    return (
        <div className="service-page" style={{height: '88vh'}}>
            <section id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center my-3">
                            <h1>Page Not Found</h1>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PageNotFound