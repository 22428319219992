import React from 'react'
import PageBanner from '../../includes/PageBanner'

function Brokerage() {
    return (
        <div className="service-page">
            <section id="Banner"><PageBanner /></section>
            <section id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center my-3">
                            <h1>Brokerage</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 my-3 text-center">
                            <img src="assets/img/services/brokerage.jpg" alt="" />
                        </div>
                        <div className="col-md-9 my-3 pl-4">
                            <p className='bin-p'><span>Eisa Trading</span><span> through its broker division does transactions throughout the world. We have an advantage through our brokerage division to leverage our client relationships and market knowledge to ensure our clients receive the best value for their material regardless of their location. Our objective is to provide our suppliers &amp; buyers an ultimate customer services to receive the most cost effective material with a steady supply. Our ability to execute and provide value to both remote generators and consumers has resulted in tremendous growth and expansion of Eisa Trading Inc.</span></p>
                            <p className='bin-p'><span>Eisa Trading</span><span> Inc. brokerage team is staffed with experienced &amp; well qualified professionals, who have a keen eye on both domestic and foreign market activity. Our brokerage team can provide our clients with up to the minute competitive quotes utilizing Comex,American Metal market and London Metal exchange.</span></p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Brokerage