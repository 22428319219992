
import React, { useState, useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Switch, useLocation, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useSpring, animated, useSpringRef } from 'react-spring'
import Navbar from "./includes/Navbar";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Footer from "./includes/Footer";
import Brokerage from "./pages/services/Brokerage";
import BinTruckService from "./pages/services/BinTruckService";
import MaterialEquipment from "./pages/services/MaterialEquipment";
import PublicPeddler from "./pages/services/PublicPeddler";
import IndustrialCommercial from "./pages/services/IndustrialCommercial";
import FerrousMetals from "./pages/product-category/FerrousMetals";
import Product from "./pages/product-category/Product";
import NonFerrousMetals from "./pages/product-category/NonFerrousMetals";
import Miscellaneous from "./pages/product-category/Miscellaneous";
import HsPolicy from "./pages/HsPolicy";
import OurTeam from "./pages/OurTeam";
import Career from "./pages/Career";
import SchedulePickup from "./pages/SchedulePickup";
import PageNotFound from "./pages/PageNotFound";
import Home  from  "../src/pages/Home"
import Prime from "../src/pages/product-category/Prime"
import AutoWreaker from './pages/services/AutoWreaker';


function App() {
  const [screen, setScreen] = useState(true);
  const transitionOutRef = useSpringRef()
  const divStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: transitionOutRef,
    config: {
      duration: 2000
    },
    delay: 500
  })
  // useChain([divStyle]);
  useEffect(() => {
    transitionOutRef.start()
    // Update the document title using the browser API
    setTimeout(() => {
      setScreen(false);
    }, 3500);
  });
  return (
    screen ? 
    <div className="simple-trans-main text-center">
      <animated.div style={{ ...divStyle, backgroundColor: '#ff7b02' }}><div style={{lineHeight: '.7'}}>EISA TRADING INC.<br/><span style={{fontSize: '4rem', fontWeight: '400'}}>World Wide Network</span></div></animated.div>
    </div> :
    <Router>
      <div>
        <Navbar/>
          <RouteContent />
        <Footer />
      </div>
    </Router>
  );
}

function RouteContent() {
  const location = useLocation()
  return (
    <div>
      <TransitionGroup>
            <CSSTransition
              timeout={3000}
              key={location.key}
            >
            <Switch>
                <Route exact path="/"><Home/></Route>
                <Route path="/brokerage"><Brokerage/></Route>
                <Route path="/bin-truck-service"><BinTruckService/></Route>
                <Route path="/material-handling-equipment"><MaterialEquipment/></Route>
                <Route path="/public-peddler"><PublicPeddler/></Route>
                <Route path="/industrial-commercial"><IndustrialCommercial/></Route>
                <Route path="/prime-product"><FerrousMetals/></Route>
                <Route path="/ferrous-non-ferrous-metals"><NonFerrousMetals/></Route>
                <Route path="/car-and-car-parts"><Miscellaneous/></Route>
                <Route path="/product/:tag"><Product/></Route>
                <Route path="/hs-policy"><HsPolicy/></Route>
                <Route path="/our-team"><OurTeam/></Route>
                <Route path="/career"><Career/></Route>
                <Route path="/about-us"><About/></Route>
                <Route path="/contact-us"><Contact/></Route>
                <Route path="/schedule-pickup"><SchedulePickup/></Route>
                <Route path='/404'><PageNotFound /> </Route>
                <Route path='/prime'><Prime/> </Route>
                <Route path='/auto'><AutoWreaker/> </Route>
                <Redirect from='*' to='/404' />

            </Switch>
            </CSSTransition>
          </TransitionGroup>
    </div>


  )
}

export default App;
