
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import Products from '../../includes/Products';
import RecommendedProducts from './RecommendedProducts';
import PageBanner from '../../includes/PageBanner'

const Product = () => {
    const { tag } = useParams();
    const prod = Products.find(p => p.tag === tag)
    const category = {
        'prime-product': 'Prime Product Range',
        'ferrous-non-ferrous-metals': 'Ferrous & Non-Ferrous Metals',
        'car-and-car-parts': 'Car & Car Parts',
    }
    const imgUrl = '/assets/img/products/'+prod.img
    return (
        <div className="product-category-page">
            <section id="Banner"><PageBanner banner="assets\img\page-banner\PRODUCT BANNER..jpg" /></section>
            <section id="content" className="product_detail_pg">
                <div className="container">
                    <div className="row row-eq-height my-5">
                        <div className="col-md-7">
                            <img className="img-fluid" src={imgUrl} alt="" />
                        </div>
                        <div className="col-md-5 align-self-center">
                            <h2>{prod.name}</h2>
                            
                            <p>Category <Link className="category-link" to={'/' + prod.category}>{category[prod.category]}</Link></p>
                            
                            <Link to="/contact-us" className="btn rounded-0 btn-pickup">Contact Us</Link>
                        </div>
                    </div>
                    {/* <ul className="nav nav-pills product-detail-tab my-5" id="pills-tab" role="tablist">
                        <li className="nav-item active" role="presentation">
                            <a id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Description</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Profile</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane entry-content fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <h2>Description</h2>
                            <p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">2</div>
                    </div> */}
                </div>
            </section>
            <section>
                <div className="container">
                    <RecommendedProducts product={prod} />
                </div>
            </section>
        </div>
    );
  }
  
  export default Product