import React from 'react'
import { Link } from "react-router-dom";
import './Navbar.css'
function Navbar() {
    return (
        <header className="header">
            <div id="main-nav" className="navbar">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <div className="navbar-brand">
                            {/* <Link to="/" rel="home" itemProp="url">
                                <img width="45" height="45" src="/assets/img/eisa-logo.png" alt="Eisa Trading" />
                            </Link> */}
                            <a href="/">
                                <img src="assets\img\page-banner\logo (1).png" alt="Eisa Trading" />
                            </a>
                        </div>
                        {/* <Link className="navbar-brand" to="/">Eisa Trading</Link> */}
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item active">
                                    <Link to="/" className="nav-link">Home</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Products
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <Link className="dropdown-item" to="/prime">Prime and Secondary</Link>
                                    <Link className="dropdown-item" to="/ferrous-non-ferrous-metals">Ferrous and non Ferrous</Link>
                                    <Link className="dropdown-item" to="/car-and-car-parts">Cars and Car Parts</Link>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Services
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <Link className="dropdown-item" to="/auto">Auto wrecker</Link>
                                    <Link className="dropdown-item" to="/public-peddler">Public &#038; Peddler</Link>
                                    <Link className="dropdown-item" to="/industrial-commercial">Industrial &#038; Commercial</Link>                                  
                                    <Link className="dropdown-item" to="/bin-truck-service">Bin &#038; Truck Services</Link>
                                    <Link className="dropdown-item" to="/material-handling-equipment">Material Handling &#038; Equipment</Link>
                                    <Link className="dropdown-item" to="/brokerage">Brokerage</Link>
                                    
                                    </div>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link" to="/hs-policy">H & S Policy</Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link className="nav-link" to="/our-team">Our Team</Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link className="nav-link" to="/career">Career</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/about-us">About Us</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact-us">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
  }
  
  export default Navbar;