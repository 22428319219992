import React from 'react'
import PageBanner from '../../includes/PageBanner'


function PublicPeddler() {
    return (
        <div className="service-page">
            <section id="Banner"><PageBanner /></section>
            <section id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center my-3">
                            <h1>Public & Peddler</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 my-3 text-center">
                            <img className='auto-image' src="assets/img/services/PUBLIC AND PEDDLER.JPG" alt="" />
                        </div>
                        <div className="col-md-9 my-3 pl-4">
                            <p className='bin-p'><span>We are</span><span> open to public & peddlers for the last over 3 years now. No account is small or big, we welcome everyone with warm open arms. Any quantity small or big, you can exchange for top$ cash.</span></p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PublicPeddler