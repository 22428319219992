import React from 'react'
import { Link } from 'react-router-dom';
import Products from '../../includes/Products'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import PageBanner from '../../includes/PageBanner'

function Prime() {
    const products = Products.filter(p => p.category === 'prime-product')
    return (
        <div className="product-category-page">
            <section id="Banner"><PageBanner banner="assets\img\page-banner\PRODUCT BANNER..jpg" /></section>
            <section id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <h1 className="page-title">Prime and Secondary </h1>
                        </div>
                    </div>
                    <div className="py-3">
                        <TransitionGroup className="row">
                            {products.map(p => {
                                const imgUrl = '/assets/img/products/' + p.img
                                const productRoute = '/product/'+p.tag
                                return (
                                    <CSSTransition key={p.id} timeout={300} classNames="col-md-4">
                                        <div className="col-md-4 mt-2 text-center">
                                            <div className="product_box" style={{backgroundImage: "url('assets/img/home-products/p1_bg.png')"}}>
                                               
                                                    <img className="img-fluid" src={imgUrl} alt="" />
                                                    <h4 className="product-title mt-4">{p.name.toUpperCase()}</h4>
                                               
                                                
                                                <Link to={productRoute} className="btn rounded-0 btn-pickup">Read More</Link>
                                            </div>
                                        </div>
                                    </CSSTransition>
                                )
                            })}
                        </TransitionGroup>
                        
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Prime