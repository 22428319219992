import React from 'react'
import { Link } from 'react-router-dom';
import Products from '../../includes/Products'
import PageBanner from '../../includes/PageBanner'
function NonFerrousMetals() {
    const products = Products.filter(p => p.category === 'ferrous')
    const non = Products.filter(p => p.category === 'non-ferrous-metals')
    return (
        <div className="product-category-page">
            <section id="Banner"><PageBanner banner="assets\img\page-banner\PRODUCT BANNER..jpg" /></section>
            <section id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <h1 className="page-title1">Ferrous Metals</h1>
                        </div>
                    </div>
                    <div className="row ">
                        {products.map(p => {
                            const imgUrl = '/assets/img/products/' + p.img
                            const productRoute = '/product/'+p.tag
                            return (
                                <div className="col-md-4 " key={p.id}>
                                     <div className="product_box" style={{backgroundImage: "url('assets/img/home-products/p1_bg.png')"}}>
                                     
                                        <Link to={productRoute}>
                                            <img className="img-fluid" src={imgUrl} alt="" />
                                            <h4 className="product-title mt-4">{p.name.toUpperCase()}</h4>
                                        </Link>
                                        
                                        <Link to={productRoute} className="btn rounded-0 btn-pickup">Read More</Link>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <h1 className="page-title2"> Non-Ferrous Metals</h1>
                        </div>
                    </div>


                    <div className="row ">
                        {non.map(p => {
                            const imgUrl = '/assets/img/products/' + p.img
                            const productRoute = '/product/'+p.tag
                            return (
                                <div className="col-md-4 " key={p.id}>
                                     <div className="product_box" style={{backgroundImage: "url('assets/img/home-products/p1_bg.png')"}}>
                                        <Link to={productRoute}>
                                            <img className="img-fluid" src={imgUrl} alt="" />
                                            <h2 className="product-title mt-4">{p.name.toUpperCase()}</h2>
                                        </Link>
                                        
                                        <Link to={productRoute} className="btn rounded-0 btn-pickup">Read More</Link>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default NonFerrousMetals