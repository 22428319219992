import React, { useRef, useState } from 'react'
import SweetAlert from 'sweetalert2-react';
import PageBanner from '../includes/PageBanner';


function Contact() {
    const iconStyle = {height:'15px'}
    const addressStlye = {fontSize:'15px', color: '#414042'}
    // const addressHeadStyle = {color: 'red'}
    // const iframeStyle = {width: '100%', height: '300px'}
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const messageRef = useRef();

    const [successAlert, setSuccessAlert] = useState(false)
    
    function submitHandler(event){
        event.preventDefault();

        const contactData = {
            firstName: firstNameRef.current.value,
            lastName: lastNameRef.current.value,
            email: emailRef.current.value,
            message: messageRef.current.value,
          };
        console.log(contactData)
        setSuccessAlert(true)
        contactFormReset()
    }

    function contactFormReset() {
        firstNameRef.current.value = ''
        lastNameRef.current.value = ''
        emailRef.current.value = ''
        messageRef.current.value = ''
    }
    return (
        <div className="service-page">
            <section id="Banner"><PageBanner banner="assets\img\page-banner\CONTACT US.jpg" /></section>
            <section id="content">
                <div className="container px-5">
                    <div className="row my-3">
                        <div className="col-md-6  text-center">
                            <img className="img-fluid" src="/assets/img/lancing-drive-768x432.jpg" alt="" />
                            <div>
                                <h2 className="mt-3">
                                    <span> Head Office &amp; Warehouse</span>
                                    <br/>
                                    <img src="/assets/img/location.png" alt="" style={iconStyle} />
                                    <span style={addressStlye}> <a href="https://www.google.com/maps/place/255+Lancing+Dr,+Hamilton,+ON+L8W+3W8,+Canada/@43.190713,-79.835671,10z/data=!4m5!3m4!1s0x882c9a040eb12071:0x26bac1c03751cf1b!8m2!3d43.1907133!4d-79.8356706?hl=en-US" target="_blank" rel="noreferrer"> Location 1: 255 Lancing Drive, Hamilton, ON.L8W 3W8 </a></span>​
                                   
                                </h2>
                            </div>
                            {/* <div>
                            <iframe style={iframeStyle} title="1" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?q=%20255%20Lancing%20Drive%2C%20Hamilton%2CON.%20L8W%203W8&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" aria-label=" 255 Lancing Drive, Hamilton,ON. L8W 3W8"></iframe>
                            </div> */}
                        </div>
                        <div className="col-md-6 text-center">
                            <img className="img-fluid" src="/assets/img/New-Project-768x432.jpg" alt="" />
                            <div>
                                <h2 className="mt-3">
                                    <span>Office, Yard &amp; Warehouse</span>
                                    <br/>
                                    <img src="/assets/img/location.png" alt="" style={iconStyle} />
                                    <span style={addressStlye}> <a href="https://www.google.com/maps/place/842+Nebo+Rd,+Hannon,+ON+L0R+1P0,+Canada/@43.172283,-79.847247,10z/data=!4m5!3m4!1s0x882c90bc0372051b:0x1961fd142b95abed!8m2!3d43.1722831!4d-79.8472474?hl=en-US" target="_blank" rel="noreferrer"> Location 2: 842 Nebo Road Hamilton,ON.L0R 1P0</a></span>​
                                    <br/>
                                  
                                   

                                   
                                </h2>
                            </div>
                            {/* <div>
                            <iframe style={iframeStyle} title="2" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?q=842%20Nebo%20Road%20Hamilton%2CON.L0R%201P0&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" aria-label="842 Nebo Road Hamilton,ON.L0R 1P0"></iframe>
                            </div> */}
                        </div>

                       
                    </div>

                    <div className="row contact_info_row py-3">
                    <div className="col-md-4 text-center">
                       
                       <img src="/assets/img/contact/call.jpg" alt="" width="80px" />  <br/> <span className="contact_info_content"> (905) 645 0572 / (905) 228 6555</span>
                       </div>
                     
                       <div className="col-md-4  text-center">
                       
                                   <img src="/assets/img/contact/email.jpg" alt="" width="80px" />  <br/><span className="contact_info_content">&nbsp; info@eisatradinginc.ca </span>
                       </div>
                       <div className="col-md-4  text-center">
                       
                       <img src="/assets/img/contact/fax.jpg" alt="" width="80px" />  <br/> <span className="contact_info_content"> (647) 439 1588</span>
           </div>
                    </div>
                </div>
            </section>
            <section id="contact">
                <div className="container py-3 px-5">
                    <h2 className="text-center">Contact Detail</h2>
                    <form onSubmit={submitHandler}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="firstName">First Name<span className="text-danger">*</span></label>
                                    <input className="form-control" type="text" id="firstName" required ref={firstNameRef}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="lastName">Last Name<span className="text-danger">*</span></label>
                                    <input className="form-control" type="text" id="lastName" required ref={lastNameRef} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="email">Email<span className="text-danger">*</span></label>
                                    <input className="form-control" type="email" id="email" required ref={emailRef}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="message">Message<span className="text-danger">*</span></label>
                                    <textarea className="form-control" id="message" rows="5" required ref={messageRef} ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <button className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            <SweetAlert
                show={successAlert}
                icon="success"
                title="Contact"
                text="Your Message detail submitted successfully"
                onConfirm={() => setSuccessAlert(false)}
            />
        </div>
    );
  }
  
  export default Contact;