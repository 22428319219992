import React, { useRef, useState } from 'react'
import SweetAlert from 'sweetalert2-react';
import PageBanner from '../includes/PageBanner';

const SchedulePickup = () => {
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const companyNameRef = useRef();
    const productNameRef = useRef();
    const quantityRef = useRef();
    const isReadyRef = useRef();
    const addressRef = useRef();
    const messageRef = useRef();

    const [successAlert, setSuccessAlert] = useState(false)

    function submitHandler(event){
        event.preventDefault();

        const contactData = {
            firstName: firstNameRef.current.value,
            lastName: lastNameRef.current.value,
            companyName: companyNameRef.current.value,
            message: messageRef.current.value,
          };
        console.log(contactData)
        setSuccessAlert(true)
    }

    return (
        <div className="service-page">
            <section id="Banner"><PageBanner banner="/assets/img/page-banner/schedule-a-pickup.jpg" /></section>
            <section id="contact">
                <div className="container py-3 px-5">
                    <h2 className="text-center">Schedule a Pickup</h2>
                    <form onSubmit={submitHandler}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="firstName">First Name<span className="text-danger">*</span></label>
                                    <input className="form-control" type="text" id="firstName" required ref={firstNameRef}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="lastName">Last Name<span className="text-danger">*</span></label>
                                    <input className="form-control" type="text" id="lastName" required ref={lastNameRef} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="companyName">Company Name<span className="text-danger">*</span></label>
                                    <input className="form-control" type="text" id="companyName" required ref={companyNameRef}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="productName">Product Name<span className="text-danger">*</span></label>
                                    <input className="form-control" type="text" id="productName" required ref={productNameRef}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="quantity">Quantity</label>
                                    <input className="form-control" type="text" id="quantity" required ref={quantityRef}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="isReady">Product is ready to Pick-UP<span className="text-danger">*</span></label>
                                    <select className="form-control" ref={isReadyRef}>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="address">Address</label>
                                    <input className="form-control" type="text" id="address" required ref={addressRef}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="message">Message<span className="text-danger">*</span></label>
                                    <textarea className="form-control" id="message" rows="5" required ref={messageRef} ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <button className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            <SweetAlert
                show={successAlert}
                icon="success"
                title="Schedule Pickup"
                text="Your schedule pickup detail submitted successfully"
                onConfirm={() => setSuccessAlert(false)}
            />
        </div>
    )
}

export default SchedulePickup