import React from 'react'
import PageBanner from '../../includes/PageBanner'

function BinTruckService() {
    return (
        <div className="service-page">
            <section id="Banner"><PageBanner /></section>
            <section id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center my-3">
                            <h1>Bin & Truck Services</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 my-3 text-center">
                            <img src="assets/img/services/bin-truck-services.jpg" alt="" />
                        </div>
                        <div className="col-md-9 my-3 pl-4">
                            <p className='bin-p'>We provide the Trucks , Bins and Container fleets services fit to your needs. 20, 30 & 40 yard bins are available for short and long term basis. <br/> Our team of service consultants is available 24 X 7 for the convenience of our customers.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BinTruckService