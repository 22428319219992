import React from 'react'
import './Footer.css'

const height = { height: "15px" }

function Footer() {
    return (
        <footer id="footer">

	
            <div className="container">

                
                <div className="row text-center">
                    <div className="col-md-3 py-3">
                        <img src="/assets/img/location.png" alt="" style={height} />  Unit# 1, 255 Lancing  Drive, Hamilton, Ontario, L8W 3W8, CANADA.
                    </div>
                    <div className="col-md-3 py-4">
                        <img src="/assets/img/mail-icon.png" alt="" style={height} />  info@eisatradinginc.ca
                    </div>
                    <div className="col-md-3 py-4">
                        <img src="/assets/img/phone.png" alt="" style={height} />  (905) 645 0572 
                    </div>
                    <div className="col-md-3  py-4">
                        <p id="zerif-copyright">@2021. All Rights Reserved.</p> 
                        
                    </div>
                </div>
            </div> 

        </footer>
    );
}

export default Footer