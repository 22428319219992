import React from 'react'
import PageBanner from '../../includes/PageBanner'

function IndustrialCommercial() {
    return (
        <div className="service-page">
            <section id="Banner"><PageBanner /></section>
            <section id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center my-3">
                            <h1>Industrial & Commercial</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 my-3 text-center">
                            <img src="assets/img/services/industrial-commercial.jpg" alt="" />
                        </div>
                        <div className="col-md-9 my-3 pl-4">
                            <p className='bin-p'><span>We can</span><span> cater Industrial as well as Commercial accounts deal in any form or Metal or Metal Scraps. All types of Non-Hazardous Scrap Metal can be dropped off at our facilities or we can arrange a pickup as per your needs. Please contact us for pickup schedule.</span></p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default IndustrialCommercial