import React from 'react'
import PageBanner from '../../includes/PageBanner'



const AutoWreaker = () => {
    return (
        <div className="service-page">
            <section id="Banner"><PageBanner /></section>
            <section id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center my-3">
                            <h1>Auto Wrecker</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 my-3 text-center">
                            <img className='auto-image' src ="assets/img/services/AUTO WRECKER IMAGE.jpeg" alt="" />
                        </div>
                        <div className="col-md-9 my-3 pl-4">
                            <p className='autop'><span> Eisa trading inc</span><span> is a professional car wrecker company that specialises in sourcing quality spare parts for cars. Our expert team sources and reconditions all types of salvageable car parts, so our customers can purchase quality items for a significantly reduced price compared to brand new parts.</span></p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AutoWreaker
