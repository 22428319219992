import React from 'react'
import PageBanner from '../includes/PageBanner'

const HsPolicy = () => {
    return (
        <div className="service-page">
            <section id="Banner"><PageBanner banner="assets\img\page-banner\H&S POLICY.jpg" /></section>
            <section id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center my-3">
                            <h1>Health & Safety policy</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 my-3 text-center">
                            <img src="assets/img/HEALTH-AND-SAFETY.jpg" alt="" />
                        </div>
                        <div className="col-md-9 my-3 pl-4">
                            <p className='bin-p'><span>Safety first! EISA Trading Inc.</span><span>  takes the health and safety of our staff very seriously. That being said, we have put in place certain precautions, protocols and procedures that our team members follow on a day to day basis. We also equip our staff with all the necessary tools and safety gear needed to ensure a safe working environment.</span></p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HsPolicy