import React from 'react'
import PageBanner from '../../includes/PageBanner'

function MaterialEquipment() {
    return (
        <div className="service-page">
            <section id="Banner"><PageBanner /></section>
            <section id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center my-3">
                            <h1>Material Handling & Equipment</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 my-3 text-center">
                            <img className='auto-image' src="assets/img/services/MATERIAL AND HANDLING.png" alt="" />
                        </div>
                        <div className="col-md-9 my-3 pl-4">
                            <p className='bin-p'><span>We have</span><span> a wide range of machinery and material handling equipment which help in processing, loading, unloading, and material movements.
                            Cranes, Forklifts, Bobcats, Shears, Front end Loader, Torching & Racking for sheets & log products are readily available all the time.</span></p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MaterialEquipment