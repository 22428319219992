const Products = [
    {
        id: 1,
        name: 'Blooms',
        tag: 'blooms',
        price: '6.00',
        category: 'prime-product',
        img: 'blooms.jpg'
    },
    {
        id: 33,
        name: 'Cold Rolled Coils',
        tag: 'Cold Rolled Coils',
        price: '',
        category: 'prime-product',
        img: 'Cold Rolled Coils-1.JPG'
    },
    {
        id: 34,
        name: 'CR-bright Bars',
        tag: 'CR-bright Bars',
        price: '',
        category: 'prime-product',
        img: 'CR-Bright Bars (1).jpg'
    },
    {
        id: 35,
        name: 'Galvanized Coils',
        tag: 'Galvanized Coils',
        price: '',
        category: 'prime-product',
        img: 'Galvanized Coils.JPG'
    },
    {
        id: 36,
        name: 'Galvanized Steel Pipes',
        tag: 'Galvanized Steel Pipes',
        price: '',
        category: 'prime-product',
        img: 'Galvanized Steel Pipes.jpeg'
    },
    {
        id: 37,
        name: 'HS Sections',
        tag: 'Hollow Structual Sections',
        price: '',
        category: 'prime-product',
        img: 'Hollow Structual Sections.jpg'
    },
    {
        id: 38,
        name: 'Hot Rolled Coils',
        tag: 'Hot Rolled Coils',
        price: '',
        category: 'prime-product',
        img: 'Hot Rolled Coils.jpg'
    },
    
    
    // {
    //     id: 40,
    //     name: 'Scaffolding Pipes',
    //     tag: 'Scaffolding Pipess',
    //     price: '',
    //     category: 'prime-product',
    //     img: 'Scaffolding Pipes.jpg'
    // },
    {
        id: 41,
        name: 'Square Billet',
        tag: 'Square Billet',
        price: '',
        category: 'prime-product',
        img: 'Square Billet2.jpg'
    },
    // {
    //     id: 42,
    //     name: 'Temporary Fence Panels',
    //     tag: 'Temporary Fence Panels',
    //     price: '',
    //     category: 'prime-product',
    //     img: 'Temporary Fence Panels.jpg'
    // },
    {
        id: 43,
        name: 'Wire Rod',
        tag: 'Wire Rod',
        price: '',
        category: 'prime-product',
        img: 'Wire Rod.jpg'
    },
    {
        id: 80,
        name: 'Cold Rolled Steel Pipes',
        tag: 'Cold Rolled Steel Pipes',
        price: '',
        category: 'prime-product',
        img: 'Cold Rolled Steel Pipes.jpg'
    },
    {
        id: 81,
        name: 'Round Billet',
        tag: 'Round Billet',
        price: '',
        category: 'prime-product',
        img: 'Round Billet.jpg'
    },
   

    {
        id: 2,
        name: 'Cobble Plates',
        tag: 'cobble-plates',
        price: '6.00',
        category: 'prime-product',
        img: 'cobble-plates.jpg'

    },

    {
        id: 3,
        name: 'Diamond Plates',
        tag: 'diamond-plate',
        price: '6.00',
        category: 'prime-product',
        img: 'diamond-plate.jpg'
    },
    {
        id: 4,
        name: 'MS Plates',
        tag: 'ms-plates',
        price: '6.00',
        category: 'prime-product',
        img: 'ms-plates.jpg'
    },
    {
        id: 5,
        name: 'Rebars',
        tag: 'rebars',
        price: '6.00',
        category: 'prime-product',
        img: 'rebars.jpg'
    },
    {
        id: 6,
        name: 'Auto Cast Motor Scrap',
        tag: 'auto-cast-rotor-scrap',
        price: '6.00',
        category: 'ferrous',
        img: 'f-Auto-Cast-Rotor-Scrap.jpg'
    },
    {
        id: 50,
        name: 'Auto Cast Aluminum',
        tag: 'Auto Cast Aluminum',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'Auto Cast Aluminum.jpg'
    },
    {
        id: 58,
        name: 'Battery Scrap',
        tag: 'Battery Scrap',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'Battery Scrap.jpg'
    },
   {
        id: 54,
        name: 'Lead Scrap',
        tag: 'Lead Scrap',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'Lead Scrap.jpg'
    },
    {
        id: 93,
        name: 'Old Sheet',
        tag: 'Old Sheet',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'Old Sheet.jpg'
    },
    {
        id: 94,
        name: 'OTM Scrap',
        tag: 'OTM Scrap',
        price: '6.00',
        category: 'ferrous',
        img: 'OTM scrap.jpg'
    },
    {
        id: 95,
        name: 'Sealed Units',
        tag: 'Sealed Units',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'Sealed Units.jpg'
    },
    {
        id: 96,
        name: 'Silicon sheets',
        tag: 'Silicon sheets',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'Silicon sheets.jpg'
    },
    {
        id: 97,
        name: 'White Goods',
        tag: 'White Goods',
        price: '6.00',
        category: 'ferrous',
        img: 'White Goods.jpg'
    },
    {
        id: 98,
        name: 'Acsr',
        tag: 'Acsr',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'Acsr.jpg'
    },
    // {
    //     id: 53,
    //     name: 'Silicon Steel',
    //     tag: 'Silicon Steel',
    //     price: '6.00',
    //     category: 'non-ferrous-metals',
    //     img: 'Silicon Steel-1.JPG'
    // },
    {
        id: 52,
        name: 'Small E-Motors',
        tag: 'Small E-Motors',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'Small E-Motors.jpg'
    },
    {
        id: 63,
        name: 'Transformer Scrap',
        tag: 'Transformer Scrap',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'Transformer Scrap.jpg'
    },
    {
        id: 80,
        name: 'Car Harness Wire',
        tag: 'Car Harness Wire',
        price: '6.00',
        category: 'non-ferrous-metals',
        img:'Car Harness Wire.jpg'
    },
    {
        id: 90,
        name: 'Dirty Aluminum',
        tag: 'Dirty Aluminum',
        price: '6.00',
        category: 'non-ferrous-metals',
        img:'Dirty Aluminum.jpg'
    },
    {
        id: 91,
        name: 'Electrical Motor Scrap',
        tag: 'Electrical Motor Scrap',
        price: '6.00',
        category: 'non-ferrous-metals',
        img:'Electrical Motor Scrap.jpg'
    },
    {
        id: 92,
        name: 'Engine Combo Scrap',
        tag: 'Engine Combo Scrap',
        price: '6.00',
        category: 'non-ferrous-metals',
        img:'Engine Combo Scrap.jpg'
    },
    {
        id: 7,
        name: 'HMS',
        tag: 'hms',
        price: '6.00',
        category: 'ferrous',
        img: 'f-HMS1-2.jpg'
    },
    {
        id: 8,
        name: 'HMS Bundles',
        tag: 'hms-bundles',
        price: '6.00',
        category: 'ferrous',
        img: 'f-HMS-Bundles.jpg'
    },
    {
        id: 9,
        name: 'PNS Scrap',
        tag: 'pns-scrap',
        price: '6.00',
        category: 'ferrous',
        img: 'f-PNS-Scrap.jpg'
    },
    {
        id: 10,
        name: 'Shredded Scrap',
        tag: 'shredded-scrap',
        price: '6.00',
        category: 'ferrous',
        img: 'f-Shredded-Scrap-1.jpg'
    },
    {
        id: 12,
        name: 'Engine and transmission',
        tag: 'Engine and transmission',
        price: '6.00',
        category: 'car-and-car-parts',
        img: 'Engine and transmission.jpeg'
    },
    {
        id: 14,
        name: 'Alternators & Starters',
        tag: 'Alternators & Starters',
        price: '6.00',
        category: 'car-and-car-parts',
        img: 'Alternators & Starters.jpg'
    },

    {
        id: 16,
        name: 'Tool Steel Scrap',
        tag: 'tool-steel-scrap',
        price: '6.00',
        category: 'ferrous',
        img: 'f-Tool-Steel-Scrap.jpg'
    },
    {
        id: 17,
        name: 'Used Tin',
        tag: 'used-tin',
        price: '6.00',
        category: 'ferrous',
        img: 'f-Used-Tin.jpg'
    },
    {
        id: 78,
        name: 'Used Rail',
        tag: 'used Rail',
        price: '6.00',
        category: 'ferrous',
        img: 'Used Rail.jpg'
    },
    {
        id: 77,
        name: 'New Tin Bundles',
        tag: 'New Tin Bundles',
        price: '6.00',
        category: 'ferrous',
        img: 'New Tin Bundles-1.JPG'
    },
    {
        id: 18,
        name: 'Alternators & Starters',
        tag: 'alternators-starters',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'Alternators & Starters.jpg'
    },
    {
        id: 19,
        name: 'Seats',
        tag: 'seats',
        price: '6.00',
        category: 'car-and-car-parts',
        img: 'cp-SEATS.jpg'
    },
 
    {
        id: 20,
        name: 'Aluminum & Chrome Rims',
        tag: 'aluminum-chrome-rims',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'Aluminum & Chrome Rims.jpg'
    },
    {
        id: 21,
        name: 'Aluminum Cable',
        tag: 'aluminum-cable',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'Aluminum Cable.jpg'
    },
    {
        id: 22,
        name: 'Aluminum UBC',
        tag: 'aluminum-ubc',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'Aluminum UBC.jpg'
    },
    {
        id: 23,
        name: 'Lead Scrap',
        tag: 'lead-scrap',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'nf-Lead-Scrap.jpg'
    },
    {
        id: 24,
        name: 'HSS',
        tag: 'hss',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'nf-HSS.jpg'
    },
    {
        id: 25,
        name: 'Auto Cast Aluminum',
        tag: 'auto-cast-aluminum',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'nf-Auto-Cast-Aluminum.jpg'
    },
    {
        id: 26,
        name: 'Ballast Scrap',
        tag: 'ballast-scrap',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'Ballast Scrap.jpg'
    },
    {
        id: 27,
        name: 'E-Scrap',
        tag: 'e-scrap',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'nf-E-Scrap.jpg'
    },
    {
        id: 28,
        name: 'Engine Combo Scrap',
        tag: 'engine-combo-scrap',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'nf-Engine-Combo-Scrap.jpg'
    },

    {
        id: 30,
        name: 'Dirty Aluminum',
        tag: 'dirty-aluminum',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'nf-Dirty-Aluminum.jpg'
    },
    {
        id: 31,
        name: 'sealed Units',
        tag: 'sealed-units',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'nf-sealed-Units-1.jpg'
    },
    {
        id: 32,
        name: 'Radiators',
        tag: 'radiators',
        price: '6.00',
        category: 'non-ferrous-metals',
        img: 'nf-Radiators.jpg'
    },
   
]

export default Products