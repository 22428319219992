import React,{useState} from 'react'
import Carousel from 'react-bootstrap/Carousel'


   






const Slider2 = () => {
    const [index, setIndex] = useState(0);
  
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
    return (
       <>
      <Carousel fade={true}>
  <Carousel.Item >
    <img
      className="d-block w-100"
      src="assets/img/banner1.jpg"
      alt="First slide"
    />
    <Carousel.Caption>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item style={{transition:'.50s ease-in-out'}}  >
    <img
      className="d-block w-100"
      src="assets/img/BANNER 2..jpg"
      alt="Second slide"
    />

    <Carousel.Caption>

    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item style={{transition:'.35s ease-in-out'}}>
    <img
      className="d-block w-100"
      src="assets/img/banner4.jpg"
      alt="Third slide"
    />

    <Carousel.Caption>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item style={{transition:'.35s ease-in-out'}}>
    <img
      className="d-block w-100"
      src="assets/img/banner3.jpg"
      alt="First slide"
    />
    <Carousel.Caption>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item style={{transition:'.35s ease-in-out'}}  >
    <img
      className="d-block w-100"
      src="assets/img/banner5.jpg"
      alt="First slide"
    />
    <Carousel.Caption>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
       
       
       </>
    )
}

export default Slider2
