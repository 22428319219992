import React from 'react'
import { Link } from 'react-router-dom';
import Products from '../../includes/Products';

const RecommendedProducts = (props) => {
    console.log(props)
    const products = Products.filter(p => p.category === props.product.category && p.id !== props.product.id)

    return(
        <div className="related-product text-center">
            <h2>Related Products</h2>
            <div className="row py-3">
                {products.map(p => {
                    const imgUrl = '/assets/img/products/' + p.img
                    const productRoute = '/product/'+p.tag
                    return (
                        <div className="col-md-4 mt-2 text-center" key={p.id}>
                            <div className="products_cards">
                                <Link to={productRoute}>
                                    <img className="img-fluid" src={imgUrl} alt="" />
                                    <h2 className="product-title mt-4">{p.name.toUpperCase()}</h2>
                                </Link>

                                
                                <Link to={productRoute} className="btn rounded-0 btn-pickup">Read More</Link>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )

}

export default RecommendedProducts