import { Link } from "react-router-dom";
// import Slider from "../includes/Slider";
import Slider2 from "../includes/Slider2";



function Home() {
    const teamMembers = [
        { id: 1, name: 'Shahid Tufail', designation: 'President', phone: '647-219-5795' },
        { id: 2, name: 'Zahida Shahid', designation: 'Director', phone: '647-309-5559' },
        { id: 3, name: 'Frank Major', designation: 'Manager Sales & Marketing', phone: '905-228-6555' },
        { id: 4, name: 'Deepak Bansal', designation: 'Operations Manager', phone: '647-290-0008' },        
        { id: 5, name: 'MUHAMMAD YOUNAS', designation: 'Yard Supervisor', phone: '+92 300 4147654' },
        { id: 6, name: 'Aamir Sherazi', designation: 'Logistics Manager', phone: '905-228-6555' },
        { id: 7, name: 'Alex Stone', designation: 'Documentation & Coordination Incharge', phone: '905-228-6555' },
        { id: 8, name: 'AHTAHSAM SARWAR', designation: 'Accounts & Admin Manager', phone: '+92-315-7373775' },
        { id: 9, name: 'PRIYANKA SOLANKI', designation: 'Office Secretary', phone: '+1 (289) 339-4269' },        
        { id: 10, name: 'Nasir Shahzad', designation: 'Auto sales and marketing lead', phone: '+1 (514) 999-0023' },
        { id: 11, name: 'Ali Akbar', designation: 'Car Processing lead', phone: '+1 (905) 299-7821' },
        { id: 12, name: 'Jaskaran Singh', designation: 'Associate Yard Supervisor', phone: '+1 (647) 917-4192' },
        { id: 13, name: 'GAGAN PREET KAUR', designation: 'Associate Office Secretary', phone: '+1 (514) 999-0023' },
        { id: 14, name: 'Deepak Kumar', designation: 'Procurment Incharge', phone: '+1 (647) 832-3696' },
        
    ]
    return (
        <div id="content" className="site-content">
            {/* <Slider/> */}
            <Slider2/>
         
            <section className="our-product p-3">
                <div className="container-fluid pb-5">
                    <div className="section-header" style={{paddingBottom: '20px'}}>
                        <h2 className="theme_heading">PRODUCTS</h2>
                    </div>
                   <div className="row">
                      
                       <div className="col-md-4">
                       <Link to="/prime">
                            <div className="product_box" style={{backgroundImage: "url('assets/img/home-products/p1_bg.png')"}}>
                                
                                <h3 className="H3">PRIME & SECONDARY</h3>
                                <img src="assets/img/home-products/PRIME AND SECONDARY.JPG" alt="bin" />
                                
                            </div>
                            </Link>
                       </div>
                       
                       <div className="col-md-4">
                       <Link to="/ferrous-non-ferrous-metals">
                            <div className="product_box" style={{backgroundImage: "url('assets/img/home-products/p2_bg.png')"}}>
                                
                                <h3  className="H3">FERROUS & NON FERROUS</h3>
                                <img src="assets/img/home-products/FERROUS AND NON FERROUS.jpeg" alt="bin" />
                                
                            </div>
                            </Link >
                       </div>
                       <div className="col-md-4">
                           <Link to="/car-and-car-parts">
                            <div className="product_box" style={{backgroundImage: "url('assets/img/home-products/p3_bg.png')"}}>
                                
                                <h3  className="H3">CARS & CAR PARTS</h3>
                                <img src="assets/img/home-products/cacp.jpg" alt="bin" />
                                
                            </div>
                            </Link>
                       </div>
                       
                   </div>
                </div>
            </section>
            <section className="services services_sec"  style={{backgroundImage: "url('assets/img/services_bg.png')"}}>
                <div className="container-fluid">
                    <div className="row mt-3">
                        <div className="col-md-12 flex flex-column text-center">
                            <h2 className="theme_heading">OUR SERVICES</h2>
                            <p className="pt-3 text-white">Customer services are a keystone to EISA Trading Inc. We believe our client as our assets. </p>
                        </div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-4">
                          <div className="services_box">
                          <div className="serviceIcon">
                                <img src="assets/img/brokerage.png" alt="brokerage" />
                            </div>
                            <div className="serviceContainer text-center p-4">
                                
                                <h4 className="serviceHead mt-3"><span>BROKERAGE</span></h4>
                                <p className="serviceSub mt-4">Eisa Trading through its broker division does transactions throughout the world. We have an advantage through our brokerage division to leverage our client relationships and market knowledge to ensure our clients receive the best value for their material regardless of their location.</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                        <div className="services_box">
                            <div className="serviceIcon">
                                <img src="assets/img/bin.png" alt="bin" />
                            </div>
                            <div className="serviceContainer text-center p-4">
                                
                                <h4 className="serviceHead mt-3"><span>BIN & TRUCK SERVICES</span></h4>
                                <p className="serviceSub mt-4">We provide the Trucks, Bins and Container fleets services fit to your needs. 20, 30 & 40 yard bins are available for short and long term basis. Our team of service consultants is available 24 X 7 for the convenience of our customers.</p>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="services_box">
                            <div className="serviceIcon">
                                <img src="assets/img/material.png" alt="material" />
                            </div>
                            <div className="serviceContainer text-center p-4">
                                
                                <h4 className="serviceHead mt-3"><span>MATERIAL HANDLING & EQUIPMENT</span></h4>
                                <p className="serviceSub mt-4">We have a wide range of machinery and material handling equipment which help in processing, loading, unloading, and material movements. Cranes, Forklifts, Bobcats, Shears, Front end Loader, Torching & Racking for sheets & log products are readily available all the time.</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-team our_team_sec" style={{backgroundImage: "url('assets/img/team_bg.jpg')"}}>
                <div className="container-fluid">
                    <div className="row mt-3">
                        <div className="col-md-12 flex flex-column text-center">
                            
                            <h2 className="theme_heading">TEAM EISA</h2>
                            <p className="pt-3">The Team Structure is Designed on a Functional Team Basis and Features Results-Oriented Objectives at all Management Levels.</p>
                        </div>
                    </div>
                    <div className="row pt-4 team_members_row" data-scrollreveal="enter left after 0s over 0.1s">
                        {teamMembers.map(tm => {
                            let classes = 'col-lg-3 col-sm-3 team-box text-center'
                            if (tm.id > 8) {
                                classes = 'col-lg-3 col-sm-3 team-box text-center'
                            }
                            return (
                                <div className={classes} key={tm.id}>
                                    <div className="team-member" tabIndex={tm.id}>
                                        <div className="member-details">
                                            <h3 className="dark-text red-border-bottom">{tm.name}</h3>
                                            <div className="position">{tm.designation}</div>
                                        </div>
                                        <div className="social-icons">
                                            <ul></ul>
                                        </div>
                                        <div className="details text-center">
                                            Cell # {tm.phone}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
		            </div> 
                </div>
            </section>
            <section className="about-us about_company_content pt-3">
                <div className="container">
                    <div className="section-header">
                        <h2 className="white-text">ABOUT US</h2>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-6 col-md-6">
                            <div className="big-intro">
                                <img className="img-fluid" src=".\assets\img\about-us-section.png" alt="about-us-section"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 text-center">
                            <h3>Who We Are</h3>
                            <h5 className="goals-h5">Goals and values</h5>
                            <p className="paragraph1">Our company is recognised as a leading and innovative recycler and distributor of metal products, thanks to the efforts of a passionate and dedicated team of people who drive success every day. Eisa trading services a strong core customer base in Canada with a presence in all Ferrous and Non-Ferrous markets.  Our company is transforming the Canadian marketplace with best environmental and quality management practices in all our processes and facilities. We offer variety of metal products that meet the specifications of customers in a dynamic range of industries such as aluminium, copper, Lead, Zinc , Brass. We live in the era where the outlook for metal products in the global markets could change significantly overnight. With this in mind, we closely communicate and coordinate with us with the aim of coping with sudden and unpredictable shifts in the marketplace. Our goal is to make every effort to provide our customers with metal products, along with strategic market information, that will meet their needs and promote a competitive advantage to meet both short and long term goals.</p>
                        </div>
                    </div>
                    <div className="row row-eq-height">
                        <div className="col-lg-6 col-md-6 mt-1 text-center  align-self-center">
                            <h3>President's </h3>
                            <h5 className="goals-h5">Message</h5>
                            <p className="paragraph1">We will help you unlock the value of your scrap, while building a sustainable relationship founded on trust. Our innovative thinking is backed up with clear and transparent communication, efficient logistical support, and ongoing investment in employee training and equipment.<br/> We take Pride in our management who are equipped with extensive knowledge in the metal industry.We will help you unlock the value of your scrap, while building a sustainable relationship founded on trust. Our innovative thinking is backed up with clear and transparent communication, efficient logistical support, and ongoing investment in employee training and equipment.</p>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div>
                                <img className="img-fluid" src="assets/img/team/owner-picture.jpg" alt="owner-pic"/>
                            </div>
                        </div>
                    </div>
                    <img className="logo_footer" src="assets/img/client/top-logo.jpg" />
                </div>
            </section>
            
            <section className="our-client py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="row">


                                  <div className="Client">
                                
                                <div className="cal">
                                    <img className="client-logo" src="assets/img/client/client-1.jpg" alt="" />
                                </div>
                                <div className="cal">
                                    <img className="client-logo" src="assets/img/client/client-2.jpg" alt="" />
                                </div>
                                <div className="cal">
                                    <img className="client-logo" src="assets/img/client/client-3.jpg" alt="" />
                                </div>
                                </div>
                                <div className="Client">
                                <div className="cal">
                                    <img className="client-logo" src="assets/img/client/client-4.jpg" alt="" />
                                </div>
                                <div className="cal">
                                    <img className="client-logo" src="assets/img/client/client-5.jpg" alt="" />
                                </div>
                                <div className="cal">
                                    <img className="client-logo" src="assets/img/client/client-6.jpg" alt="" />
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pickup pickup_sec py-2">
                <div className="container">
                    <div className="row my-3">
                        <div className="col-md-9 pr-4">
                            <p className="pickupText"><b>EISA Trading Incorporated</b> is one of the fastest growing Metal Trading <br/> House based in the Steel City of Canada - Hamilton, Ontario</p>
                        </div>
                        <div className="col-md-3 px-5">
                            <Link className="btn btn-block rounded-0 mt-3 btn-pickup" to="/schedule-pickup"> SHCEDULE A PICK UP</Link>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
  }
  
  export default Home;