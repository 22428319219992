import React from 'react'
import PageBanner from '../includes/PageBanner'

const Career = () => {
    return (
        <div className="service-page">
            <section id="Banner"><PageBanner banner="assets\img\page-banner\CAREER BANNER..jpg" /></section>
            <section id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center my-3">
                            <h1>Career</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 my-3 pl-4">
                            <p>EISA Trading Inc. combines the tireless working enthusiasm of a medium-sized company with the resources and forward-looking vision to become a truly global entity in the metal trade.<br />
                            It is our objective to engage and retain the best work force around in our industry, and we are proud to continuously succeed in this endeavor.<br />
                            As one of the fastest growing name in our sector we are constantly need fresh &amp; capable talent and to make them resourceful thinkers.<br />
                            If you would like the opportunity to help grow our business then we are keen to hear from you.<br />
                            For more information about careers please send your CV/Resume with confidence to&nbsp;<a href="mailto:HR@eisatradinginc.ca">HR@eisatradinginc.ca</a></p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Career