import React from 'react'
import PageBanner from '../includes/PageBanner'

const OurTeam = () => {
    return (
        <div className="service-page">
            <section id="Banner"><PageBanner banner="/assets/img/page-banner/our-team.jpg" /></section>
            <section id="content">
                <div className="container">
                    <div className="row my-3">
                        <div className="col-md-4 mt-2 text-center">
                            <img src="/assets/img/team/1.jpg" alt="1" />
                            <h2>Navdeep</h2>
                        </div>
                        <div className="col-md-4 mt-2 text-center">
                            <img src="/assets/img/team/2.jpg" alt="1" />
                            <h2>Mueller</h2>
                        </div>
                        <div className="col-md-4 mt-2 text-center">
                            <img src="/assets/img/team/3.jpg" alt="1" />
                            <h2>Peter</h2>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-md-4 mt-2 text-center">
                            <img src="/assets/img/team/4.jpg" alt="1" />
                            <h2>Dylan</h2>
                        </div>
                        <div className="col-md-4 mt-2 text-center">
                            <img src="/assets/img/team/5.jpg" alt="1" />
                            <h2>Jennifer</h2>
                        </div>
                        <div className="col-md-4 mt-2 text-center">
                            <img src="/assets/img/team/6.jpg" alt="1" />
                            <h2>Ali</h2>
                        </div>
                    </div>

                    <div className="row my-3">
                        <div className="col-md-4 mt-2 text-center">
                            <img src="/assets/img/team/7.jpg" alt="1" />
                            <h2>Dave</h2>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default OurTeam