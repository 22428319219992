import React from 'react'
import { Link } from 'react-router-dom';
import Products from '../../includes/Products'
import PageBanner from '../../includes/PageBanner'
function Miscellaneous() {
    const products = Products.filter(p => p.category === 'car-and-car-parts')
    return (
        <div className="product-category-page">
            <section id="Banner"><PageBanner banner="assets\img\page-banner\PRODUCT BANNER..jpg" /></section>
            <section id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mt-4 text-center">
                            <h1 className="page-title1 ">Car & Car Parts</h1>
                            <p className="pt-2">This is good reason why we've been in business for so long. Get to know us better and let us know <br></br>what you need! Visit our location or give us a call today for more information on our used car parts inventory in hamilton </p>
                        </div>
                    </div>
                    <div className="row py-3">

                    <div className="col-md-12 mt-2 text-center" >
                    <h1 className="page-title3">Exterior parts</h1>
                                     <div className="">
                                      
                                            <img className="img-fluid w-100" src="/assets/img/products/cp-EXTERIOR.jpg" alt="" />
                                       
                                                                            
                                    </div>
                                </div>
                    </div>
                    <div className="row py-3">
                  <div className="col-md-12">
                  <h1 className="page-title4 ">Engine and Accessories</h1>
                  </div>
                        {products.map(p => {
                            const imgUrl = '/assets/img/products/' + p.img
                            const productRoute = '/product/'+p.tag
                            return (
                                <div className="col-md-4 mt-2 text-center" key={p.id}>
                                     <div className="products_cards">
                                        <Link to={productRoute}>
                                            <img className="img-fluid" src={imgUrl} alt="" />
                                            <h2 className="product-title mt-4">{p.name.toUpperCase()}</h2>
                                        </Link>
                                        
                                        <Link to={productRoute} className="btn rounded-0 btn-pickup">Read More</Link>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Miscellaneous